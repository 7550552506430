import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Edit, Done,Star, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel } from '@mui/material';
import styled from "styled-components";
import { APIURL } from '../Global';
import './DetailsPage.css';

function HouseDetailsPage({selectedGroupId}) {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const householdId = query.get('household_id');
  const token = query.get('token');
  const groupId = query.get('selectedGroupId');

  const [houseDetails, setHouseDetails] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [selectedMember, setSelectedMember] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);    
  const [visibleRows, setVisibleRows] = useState({});  
  const [isHeadModalOpen, setIsHeadModalOpen] = useState(false);  
  const [confirmHead, setConfirmHead] = useState(false); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
    setSearchQuery('');
    setSearchResults([]);
    setSelectedIndividuals([]);
  };
  const capitalizeFirstLetter = (string) => {
    return string.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };
  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
  
    setSearchLoading(true);
    try {
      
      const searchParams = new URLSearchParams({
        group_id: groupId,
        household_id: householdId 
      });
  
      if (isNaN(query)) {
       
        searchParams.append('name', query);
        searchParams.append('family_name', query);
      } else {
       
        searchParams.append('house_no', query);
      }
  
      const response = await fetch(`${APIURL}/data_collection/api/individuals-search/?${searchParams.toString()}`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      if (response.ok && data.status === 'success') {
        setSearchResults(data.data || []);
      } else {
        setSearchResults([]);
        alert(data.message || 'Failed to fetch search results');
      }
    } catch (error) {
      console.error('Error during search:', error);
      setSearchResults([]);
      alert('An error occurred while searching');
    } finally {
      setSearchLoading(false);
    }
  };
  
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    handleSearch(e.target.value); 
  };

  const handleIndividualSelect = (individual) => {
    setSelectedIndividuals(prev => {
      if (prev.some(i => i.id === individual.id)) {
        return prev.filter(i => i.id !== individual.id);
      } else {
        return [...prev, individual];
      }
    });
  };

const tableHeaderStyle = {
  padding: '8px 10px',
  textAlign: 'left',
  fontWeight: 'bold',
  color: '#041E42',
  borderBottom: '2px solid #dddddd',
  fontSize: '14px',
};

const tableCellStyle = {
  padding: '6px 10px',
  borderBottom: '1px solid #dddddd',
  color: '#333333',
  fontSize: '14px',
};

const handleAddMembers = async () => { 
  if (selectedIndividuals.length === 0) {
    alert('Please select at least one individual to add');
    return;
  }

  let confirmMove = false; 

  try {
   
    const response = await fetch(`${APIURL}/data_collection/api/households/add-individuals/?household_id=${householdId}&confirm=${confirmMove}`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        individual_ids: selectedIndividuals.map(ind => ind.id),
         
      }),
    });

    const data = await response.json();

    if (response.ok && data.status === 'success') {
      
      alert(data.message || 'Members added successfully');
      fetchHouseDetails();
      closeSearchModal();
    } 
    else if (data.status === 'error_duplicates') {
      
      const isConfirmed = window.confirm(data.message);

      if (isConfirmed) {
        confirmMove = true;

        
        const confirmResponse = await fetch(`${APIURL}/data_collection/api/households/add-individuals/?household_id=${householdId}&confirm=${confirmMove}`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            individual_ids: selectedIndividuals.map(ind => ind.id),
            
          }),
        });

        const confirmData = await confirmResponse.json();

        if (confirmResponse.ok && confirmData.status === 'success') {
          
          alert(confirmData.message || 'Members moved successfully');
          fetchHouseDetails();
          closeSearchModal();
        } else {
        
          alert(confirmData.message || 'Failed to move members');
        }
      } else {
        
        return;
      }
    } else {
      
      alert(data.message || 'Failed to add members');
    }
  } catch (error) {
    console.error('Error adding members:', error);
    alert('An error occurred while adding members');
  }
};





  const toggleRowVisibility = (memberId) => {
    setVisibleRows(prevState => ({
      ...prevState,
      [memberId]: !prevState[memberId]
    }));
  };

  const openHeadModal = (member) => {
    setSelectedMember(member);
    setIsHeadModalOpen(true);
  };

  const closeHeadModal = () => {
    setIsHeadModalOpen(false);
    setSelectedMember(null);
    setConfirmHead(false);
  };

  
  const handleSubmit = async () => {
    let confirmHead = false;
  
    try {
      
      const response = await fetch(`${APIURL}/data_collection/api/households/set-head/?household_id=${householdId}&individual_id=${selectedMember.id}&confirm_head=${confirmHead}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          household_id: householdId,
          individual_id: selectedMember.id,
          confirm_head: confirmHead, 
        }),
      });
  
      const initialData = await response.json();
  
      
      if (initialData.status === 'error_duplicates') {
       
        const isConfirmed = window.confirm(initialData.message || 'Duplicate found. Do you want to proceed by setting this individual as the head of household?');
        
        if (isConfirmed) {
          
          confirmHead = true;
  
          
          const confirmResponse = await fetch(`${APIURL}/data_collection/api/households/set-head/?household_id=${householdId}&individual_id=${selectedMember.id}&confirm_head=${confirmHead}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({
              household_id: householdId,
              individual_id: selectedMember.id,
              confirm_head: confirmHead, 
            }),
          });
  
          const confirmResult = await confirmResponse.json();
  
          
          if (confirmResult.status === 'success') {
            alert(confirmResult.message);
            fetchHouseDetails();
            closeHeadModal();   
          } else {
            alert(confirmResult.message || 'Failed to set the head of household.');
          }
        } else {
          
          return;
        }
      } else if (initialData.status === 'success') {
        
        alert(initialData.message);
        fetchHouseDetails(); 
        closeHeadModal();   
      } else {
        
        alert(initialData.message || 'Failed to set the head of household.');
      }
    } catch (error) {
      console.error('Error setting the head of household:', error);
      alert('An error occurred while submitting the request.');
    }
  };
  




const DetailItem = ({ label, value }) => (
    <div className="detail-item">
      <span className="detail-label"><b>{label}:</b></span>
      <span className="detail-value">{value || 'N/A'}</span>
    </div>
  );

  const DetailItemPop = ({ label, value }) => (
    <div className="detail-item-pop">
      <span className="detail-label-pop"><b>{label}:</b></span>
      <span className="detail-value-pop">{value || 'N/A'}</span>
    </div>
  );

  const HeaderTitle = styled.h1`
  color: #2456ae;
  font-size: 18px;
`;
const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:400px;

  img {
    width: 50px;
    margin-right: 5px;
  }
`;
  const fetchHouseDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/summary/?household_id=${householdId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok && data.status === 'success') {
        setHouseDetails(data.data);
        setError(null);
      } else {
        setError('Failed to fetch house details.');
      }
    } catch (error) {
      setError('Error fetching house details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (householdId && token) {
      fetchHouseDetails();
    }
  }, [householdId, token]);

  const openModal = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true); 
  };

  const openModals = () => {
  
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMember(null);
  };

  if (loading) {
    return <p style={{ color: '#073980' }}>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }



  return (
    <>
    <div className="page-container">
   
    <nav className="left-navbar">
    <HeaderLogo>
            <img src="/images/company-logo.png" alt="Company Logo" />
            <HeaderTitle>CODEDC</HeaderTitle>
          </HeaderLogo>
    </nav>
    <div className="details-container">
    <h1 style={{ fontWeight: 'bold', color: '#041E42' ,fontSize:22}}>Household Details</h1>
    {houseDetails ? (
     <div className="details-grid">
     <DetailItem label="House Number" value={houseDetails.house_no || 'N/A'} />
     <DetailItem label="House Name" value={houseDetails.house_name || 'N/A'} />
     <DetailItem label="Family Name" value={houseDetails.family_name || 'N/A'} />
     <DetailItem label="Total Members" value={houseDetails.total_house_members || 'N/A'} />
     <DetailItem label="Total Voters" value={houseDetails.total_house_voters || 'N/A'} />
     <DetailItem label="National Expatriates" value={houseDetails.total_house_national_expatriates || 'N/A'} />
     <DetailItem label="International Expatriates" value={houseDetails.total_house_international_expatriates || 'N/A'} />
     <DetailItem label="Land" value={houseDetails.land ? 'Yes' : 'No'} />
     <DetailItem label="House Status" value={houseDetails.house_status || 'N/A'} />
     <DetailItem label="Electricity" value={houseDetails.electricity ? 'Yes' : 'No'} />
     <DetailItem label="Drinking Water Source" value={houseDetails.drinking_water_source || 'N/A'} />
     <DetailItem label="Toilet" value={houseDetails.toilet ? 'Yes' : 'No'} />
     <DetailItem label="Waste Management" value={houseDetails.waste_management || 'N/A'} />
     <DetailItem label="Road Access" value={houseDetails.road_access || 'N/A'} />
     <DetailItem label="Ration Card Type" value={houseDetails.ration_card_type || 'N/A'} />
     <DetailItem label="Ration Card Number" value={houseDetails.ration_card_number || 'N/A'} />
     <DetailItem label="No Name on Ration Card" value={houseDetails.no_name_on_ration_card ? 'Yes' : 'No'}  />
     <DetailItem label="Eligible for Pension but not Receiving" value={houseDetails.eligible_for_pension_but_not_receiving ? 'Yes' : 'No'} />
     <DetailItem label="Special Treatment" value={houseDetails.special_treatment || 'N/A'} />
     <DetailItem label="Agriculture Details" value={houseDetails.agriculture_details || 'N/A'} />
     <DetailItem label="Pets" value={houseDetails.pets || 'N/A'} />
     <DetailItem label="Financial Status" value={houseDetails.financial_status || 'N/A'} />
     <DetailItem label="Religion" value={houseDetails.religion || 'N/A'} />
     <DetailItem label="Caste" value={houseDetails.caste || 'N/A'} />
     <DetailItem label="Sub Caste" value={houseDetails.sub_caste || 'N/A'} />
     <DetailItem label="Religious Organizations" value={houseDetails.religious_organizations || 'N/A'} />
     <DetailItem label="Remarks" value={houseDetails.remarks || 'N/A'} />
   </div>
   
    ) : (
      <p>No house details available.</p>
    )}
  
  <h2 style={{ color: '#041E42', marginTop: '20px', fontSize: 18 }}>
          Household Members &nbsp; 
          <button onClick={openSearchModal} className="member-button">
            Add Member
          </button>
        </h2>
      {houseDetails && houseDetails.members && houseDetails.members.length > 0 ? (
        <table className="another-table">
          <thead>
            <tr>
            <th>Sl.No</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Booth No</th>
              <th>House No</th>
              <th>House Name</th>
              <th>Age</th>
              <th>Epic No</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {houseDetails.members.map((member) => (
              <tr key={member.id}>
                <td>{member.sl_no}</td>
                <td>
        <button onClick={() => openModal(member)} className="clickable-button">
          {member.name} 
        </button>
       
        {houseDetails.head_of_household === member.id && (
            <Star style={{ color: '#b78727 ', marginLeft: '5px' ,fontSize:'12px'}} />
          )}
      </td>

                <td>{member.gender}</td>
                <td>{member.polling_booth}</td>
                <td>{member.house_no}</td>
                <td>{member.house_name}</td>
                <td>{member.age}</td>
                <td>{member.epic_no}</td>
                <td>
                  <IconButton onClick={() => toggleRowVisibility(member.id)}>
                    {visibleRows[member.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                  {visibleRows[member.id] && (
                    <>
                    <IconButton onClick={() => openHeadModal(member)}>
                      <p style={{fontSize:"14px",fontWeight:"bold",color:"#073980",fontFamily:'revert'}}>Mark as Head</p>
                    </IconButton>

                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No members found..</p>
      )}
</div>

<Dialog open={isModalOpen} onClose={closeModal}   maxWidth="lg"
>
  <DialogTitle style={{textAlign:'center',fontWeight:'bold',color:'#041E42'}}>{selectedMember?.name}'s Details</DialogTitle>
  <IconButton
      aria-label="close"
      onClick={closeModal}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  <DialogContent>
    <Grid container spacing={2} >
      <Grid item xs={6}>
        <DetailItemPop label="Name" value={selectedMember?.name} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Gender" value={selectedMember?.gender} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Mobile Number" value={selectedMember?.mobile_number} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Email" value={selectedMember?.email} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Date Of Birth" value={selectedMember?.dob} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Polling Booth Number" value={selectedMember?.polling_booth_number} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Aadhaar Number" value={selectedMember?.aadhaar_number} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Serial Number" value={selectedMember?.sl_no} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Guardian Name" value={selectedMember?.guardian_name} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Guardian Relation" value={selectedMember?.guardian_relation} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="House Name" value={selectedMember?.house_name} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Epic Number" value={selectedMember?.epic_no} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Education" value={selectedMember?.education} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Occupation" value={selectedMember?.occupation} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Marital Status" value={selectedMember?.marital_status} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Vehicles" value={selectedMember?.vehicles} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Differently Abled" value={selectedMember?.differently_abled} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Chronic Disease" value={selectedMember?.chronic_disease} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Religion" value={selectedMember?.religion} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Caste" value={selectedMember?.caste} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Sub Caste" value={selectedMember?.sub_caste} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Religious Organizations" value={selectedMember?.religious_organizations} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Social Organizations" value={selectedMember?.social_organizations} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Political Affiliation Party" value={selectedMember?.political_affiliation_party} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Political Affiliation Status" value={selectedMember?.political_affiliation_status} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Pension" value={selectedMember?.pension ? 'Yes' : 'No'} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Eligible To Vote" value={selectedMember?.eligible_to_vote ? 'Yes' : 'No'} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Expatriate" value={selectedMember?.expatriate} />
      </Grid>
      <Grid item xs={6}>
        <DetailItemPop label="Remarks" value={selectedMember?.remarks} />
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
  </DialogActions>
</Dialog>


<Dialog open={isHeadModalOpen} onClose={closeHeadModal} maxWidth="sm">
        <DialogTitle>
            Set {selectedMember?.name} as Head of Household
            <IconButton
                aria-label="close"
                onClick={closeHeadModal}
                style={{ position: 'absolute', right: 8, top: 8 }}
            >
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={confirmHead}
                        onChange={(e) => setConfirmHead(e.target.checked)}
                    />
                }
                label="Confirm Head"
                style={{ marginTop: '20px' }}
            />
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={closeHeadModal} 
                style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}
            >
                Cancel
            </Button>
            <Button 
                onClick={handleSubmit} 
                disabled={!confirmHead || isSubmitting}  
                style={{ backgroundColor: confirmHead ? '#035391' : '#cccccc', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}
            >
                Submit
            </Button>
        </DialogActions>
    </Dialog>

    <Dialog open={isSearchModalOpen} onClose={closeSearchModal} maxWidth="md" fullWidth>
  <DialogTitle>
    Search and Add Members
    <IconButton
      aria-label="close"
      onClick={closeSearchModal}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <div style={{ display: 'flex', marginBottom: '20px', marginTop: '10px' }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by name or house number..."
        value={searchQuery}
        onChange={handleSearchInputChange} 
      />
    </div>

    {searchResults.length > 0 ? (
      <div>
        <h3 style={{ color: '#041E42', marginBottom: '12px', fontSize: '16px' }}>Search Results</h3>
        <div style={{ overflowX: 'auto' }}>
          <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            backgroundColor: 'white',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
          }}>
            <thead>
              <tr style={{ backgroundColor: '#f5f5f5' }}>
                <th style={tableHeaderStyle}>Select</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>House No</th>
                <th style={tableHeaderStyle}>House Name</th>
                <th style={tableHeaderStyle}>Age</th>
                <th style={tableHeaderStyle}>Gender</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((individual) => (
                <tr key={individual.id} style={{ ':hover': { backgroundColor: '#f8f9fa' } }}>
                  <td style={tableCellStyle}>
                    <Checkbox
                      checked={selectedIndividuals.some(i => i.id === individual.id)}
                      onChange={() => handleIndividualSelect(individual)}
                      sx={{
                        color: '#035391',
                        '&.Mui-checked': { color: '#035391' },
                        padding: '4px',
                      }}
                      size="small"
                    />
                  </td>
                  <td style={tableCellStyle}> {capitalizeFirstLetter(individual.name)}</td>
                  <td style={tableCellStyle}>{individual.house_no}</td>
                  <td style={tableCellStyle}>{individual.house_name}</td>
                  <td style={tableCellStyle}>{individual.age}</td>
                  <td style={tableCellStyle}>{individual.gender}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ) : (
      !searchLoading && <p style={{ color: '#041E42' }}>No members found...</p>
    )}
  </DialogContent>
  <DialogActions>
    <Button
      onClick={handleAddMembers}
      style={{ backgroundColor: '#035391', color: 'white' }}
      disabled={selectedIndividuals.length === 0}
    >
      Add Selected Members ({selectedIndividuals.length})
    </Button>
  </DialogActions>
</Dialog>

    </div>
    <Footer>
        <div style={{ fontWeight: 'bold',marginLeft:'20px' }}>CodeDC</div>
        <div>Copyright@2024 IntPurple Technologies LLP.</div>
        <div style={{ marginRight:'20px' }} >Ver:V1.2.0</div>
      </Footer>
  </>
  );
}
const Footer = styled.footer`
  background-color: #1877F21A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  color: #073980;
  margin:auto;
`;
export default HouseDetailsPage;
