import React from 'react';
import { useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styled from 'styled-components';
import User from '../assets/User.png';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const SidebarHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
`;

const AddMember = styled.button`
  background-color: #035391;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 12px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
  
  p {
    margin: 0;
  }

  b {
    margin-left: 4px;
  }
`;

const UserImage = styled.img`
  width: 180px;
  height: 40px;
`;


const MembersTab = ({
  users,
  addUserError,
  newUser,
  selectedGroupId,
  isAddUserPopupOpen,
  isUserDetailsPopupOpen,
  handleAddUserInputChange,
  handleAddUser,
  handleMoreVertIconClick,
  handleUserDetailsPopupClose,
  setIsAddUserPopupOpen,
  handleProfileFieldChange,
  handleProfileEditOpen,
  handleSetPasswordOpen,
  setIsSetPasswordPopupOpen,
  setPasswordPopup,
  selectedUser,
  profileEditPopup,
  selectedGroup,
  isProfileEditOpen,
  setIsProfileEditOpen,
  profileFields,
  selectedGroupType,  
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  setAddUserError,
  handleSavePassword,
  handleSave,
  isSetPasswordPopupOpen,
  currentPassword, 
  storedUserRole,
  isActionDialogOpen,
  setIsActionDialogOpen,
  selectedAction,
  setSelectedAction,
  setIsUserDetailsPopupOpen,
  handleAction
}) => {
  const localGroupType = localStorage.getItem('codeDcUserGroupType');
  const getStatusColor = (status) => {
    if (status === 'suspended') {
      return 'red';
    } else {
      return 'green';
    }
  };

  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>
       <SidebarHeader>
       { (storedUserRole == 2) || (storedUserRole == 1 && selectedGroupType > localGroupType) ? (
          <AddMember onClick={() => {
            setIsAddUserPopupOpen(true);
            setAddUserError('');
          }}>
            <p> Add Admin <b>+</b></p>
          </AddMember>
        ) : null }
      </SidebarHeader>


      <div className="users-list">
        {users.map((user, index) => (
          <div key={index} className="user-card">
            <div className="options-icon" onClick={() => handleMoreVertIconClick(user)}>
              <MoreVertIcon className="icon" />
            </div>
            <div className="user-avatar">
              <div className="avatar-circle">
                <PersonIcon className="user-icon" />
                <FiberManualRecordIcon 
            className="online-icon" 
            style={{ 
              fontSize: 18, 
              color: getStatusColor(user.status) 
            }} 
          />
              </div>
            </div>
            <div className="user-details">
              <Typography variant="body2" className="user-name">{user.username}</Typography>
              <Typography variant="body2" className="user-email">{user.email}</Typography>
              
              
            </div>
          </div>
        ))}
      </div>

      <Dialog open={isAddUserPopupOpen} onClose={() => setIsAddUserPopupOpen(false)}>
        <div className="dialog-header">
          <DialogTitle className="dialog-title">Add User Details</DialogTitle>
          <CloseIcon className="close-icon" onClick={() => setIsAddUserPopupOpen(false)} />
        </div>
        <DialogContent className="inputbox">
          <div className="form-row">
          <div className="form-field-container">
  <Typography variant="body1" className="field-label">Username</Typography>
  <TextField
    name="usernameadmin"
    type="text"
    fullWidth
    variant="outlined"
    value={newUser.usernameadmin}
    onChange={handleAddUserInputChange}
    className="form-field"
  />
</div>
<div className="form-field-container">
  <Typography variant="body1" className="field-label">Password</Typography>
  <TextField
    name="passwordadmin"
    type={showPassword ? "text" : "password"}
    fullWidth
    variant="outlined"
    value={newUser.passwordadmin}
    onChange={handleAddUserInputChange}
    className="form-field"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={togglePasswordVisibility}
            edge="end"
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">First Name</Typography>
              <TextField
                name="first_name"
                type="text"
                fullWidth
                variant="outlined"
                value={newUser.first_name}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Last Name</Typography>
              <TextField
                name="last_name"
                type="text"
                fullWidth
                variant="outlined"
                value={newUser.last_name}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Email</Typography>
              <TextField
                name="email"
                type="email"
                fullWidth
                variant="outlined"
                value={newUser.email}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
  <div className="form-field-container">
    <Typography variant="body1" className="field-label">Mobile</Typography>
    <TextField
      name="mobile"
      type="text"
      fullWidth
      variant="outlined"
      value={newUser.mobile}
      onChange={handleAddUserInputChange}
      className="form-field"
      InputProps={{
        startAdornment: <InputAdornment position="start">91</InputAdornment>,
      }}
      inputProps={{
        maxLength: 10,
      }}
    />
  </div>
            <div className="form-field-container" style={{ display: 'none' }}>
            <Typography variant="body1" className="field-label">Group ID</Typography>
              <TextField
                name="group_id"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedGroupId}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Group Name</Typography>
              <TextField
                name="group_name"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedGroup}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
  <div className="form-field-container">
    <Typography variant="body1" className="field-label">User Type</Typography>
    <div className="box">
      <div className="radio-buttons">
        <label className="radio-label">
          <input
            type="radio"
            name="role"
            value="2"
            checked={newUser.role === 2}
            onChange={() => handleAddUserInputChange({ target: { name: 'role', value: 2 } })}
          />
          Admin
        </label>
        <label className="radio-label">
          <input
            type="radio"
            name="role"
            value="0"
            checked={newUser.role === 0}
            onChange={() => handleAddUserInputChange({ target: { name: 'role', value: 0 } })}
          />
          Viewer
        </label>
        <label className="radio-label">
          <input
            type="radio"
            name="role"
            value="1"
            checked={newUser.role === 1}
            onChange={() => handleAddUserInputChange({ target: { name: 'role', value: 1 } })}
          />
          Editor
        </label>
      </div>
    </div>
  </div>
</div>
          
        </DialogContent>
        <DialogActions className="dialog-actions">
          <div className="form-field-container">
            {addUserError && (
              <div className="form-field-container">
                <Typography variant="body2" color="error" align="center">
                  {addUserError}
                </Typography>
              </div>
            )}
            <Button onClick={handleAddUser} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' ,height :'30px'}}>
              Submit Data
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {profileEditPopup}
      {setPasswordPopup}

      <Dialog 
  open={isUserDetailsPopupOpen} 
  onClose={handleUserDetailsPopupClose}
  style={{ visibility: isUserDetailsPopupOpen ? 'visible' : 'hidden' }}
>
  <div className="dialog-header">
    <CloseIcon className="pop-close-icon" onClick={handleUserDetailsPopupClose} />
  </div>
  <DialogContent className="inputbox-container">
    <div className="user-avatar-container">
      <div className="avatar-circle-large">
        <PersonIcon className="user-icon-large" />
      </div>
    </div>
    <div className="user-details-container">
      <Typography variant="h6" className="user-name">{selectedUser?.username}</Typography>
      <Typography variant="body1" className="user-email">{selectedUser?.email}</Typography>
    </div>
    <div className="button-container">
    <Button onClick={() => {
  setIsUserDetailsPopupOpen(false);
  handleProfileEditOpen();
}}>Profile Edit</Button>
      <Button onClick={() => {
        setIsUserDetailsPopupOpen(false);
        handleSetPasswordOpen();
      }}>Reset Password</Button>
      <Button onClick={() => {
        setIsUserDetailsPopupOpen(false);
        setIsActionDialogOpen(true);
      }}>Delete</Button>
    </div>
  </DialogContent>
</Dialog>
      <Dialog open={isActionDialogOpen} onClose={() => setIsActionDialogOpen(false)}>
  <DialogTitle>Choose an Action</DialogTitle>
  <DialogContent>
    <RadioGroup
      value={selectedAction}
      onChange={(e) => setSelectedAction(e.target.value)}
    >
      <FormControlLabel 
        value="suspend" 
        control={<Radio />} 
        label="Suspend" 
        disabled={selectedUser?.status === 'suspended'}
      />
      <FormControlLabel 
        value="resume" 
        control={<Radio />} 
        label="Resume"
        disabled={selectedUser?.status !== 'suspended'}
      />
      <FormControlLabel 
        value="remove" 
        control={<Radio />} 
        label="Remove" 
      />
    </RadioGroup>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsActionDialogOpen(false)}>Cancel</Button>
    <Button onClick={handleAction}>
      Submit
    </Button>
  </DialogActions>
</Dialog>
      <Dialog open={isProfileEditOpen} onClose={() => setIsProfileEditOpen(false)}>
        <div className="dialog-header">
          <DialogTitle className="dialog-title">Edit Profile</DialogTitle>
        </div>
        <DialogContent className="inputbox">
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">First Name</Typography>
              <TextField
                name="first_name"
                type="text"
                fullWidth
                variant="outlined"
                value={profileFields.first_name}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Last Name</Typography>
              <TextField
                name="last_name"
                type="text"
                fullWidth
                variant="outlined"
                value={profileFields.last_name}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Email</Typography>
              <TextField
                name="email"
                type="email"
                fullWidth
                variant="outlined"
                value={profileFields.email}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Mobile</Typography>
              <TextField
                name="mobile"
                type="text"
                fullWidth
                variant="outlined"
                value={newUser.mobile}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
            <div className="form-field-container">
  <Typography variant="body1" className="field-label">Group ID</Typography>
  <TextField
    name="group_id"
    type="text"
    fullWidth
    variant="outlined"
    value={selectedGroupId} // Use selectedGroupId directly
    onChange={handleAddUserInputChange}
    className="form-field"
    disabled // Make it read-only
  />
</div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Group Name</Typography>
              <TextField
                name="group_name"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedGroup}
                onChange={handleAddUserInputChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">User Type</Typography>
              <div className="box">
                <div className="radio-buttons">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="role"
                      value="admin"
                      checked={profileFields.role === 'admin'}
                      onChange={handleProfileFieldChange}
                    />
                    Admin
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="role"
                      value="viewer"
                      checked={profileFields.role === 'viewer'}
                      onChange={handleProfileFieldChange}
                    />
                    Viewer
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="role"
                      value="editor"
                      checked={profileFields.role === 'editor'}
                      onChange={handleProfileFieldChange}
                    />
                    Editor
                  </label>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={handleSave} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px', marginRight: '10px' }}>
            Save
          </Button>
          <Button onClick={() => setIsProfileEditOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>



<style jsx>{`
  // .header-content {
  //   display: flex;
  //   align-items: center;
  //   margin-left:-30px;
  //   justify-content: space-between;
  //   width: 100%;
  // }
  // .dash-member {
  //   flex: 1;
  //   text-align: center;
  //   margin-left:-100px;
  //   margin-bottom:50px;
  // }
  // .image-container {
  //   width: 200px;
    
  //   height: 40px;
  // }
  // .image {
  //   width: 180px;
  //   height: 40px;
  //   margin-top:55px;
  //   margin-left:-100px;
  // }
  .add-member {
    display: flex;
    align-items: center;
  }
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dialog-title {
    text-align: center;
    margin-left: 130px;
    color: #035391;
    font-weight: 500;
  }
  .close-icon {
    cursor: pointer;
    margin-right: 15px;
  }
  .inputbox {
    padding: 16px;
  }
  .dialog-actions {
    justify-content: center;
    background-color: white;
    width: 200px;
    margin-left: 120px;
    margin-bottom: 20px;
    border-radius: 45px;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  .form-field-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .field-label {
    margin-bottom: 8px;
  }
  .form-field {
    width: 95%;
  }
  .box {
    border: 1px solid #ccc;
    padding: 12px;
    border-radius: 4px;
  }
  .radio-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .radio-label {
    margin-right: 16px;
  }
  .radio-input {
    margin-right: 8px;
  }
  .users-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    .users-list {
      grid-template-columns: 1fr;
    }
  }
  .user-card {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
  }
  .options-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
  .icon {
    font-size: 18px;
  }
  .user-avatar {
    margin-right: 8px;
  }
  .avatar-circle {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #ADD8E6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-icon {
    color: #555;
  }
  .online-icon {
    position: absolute;
    top: 0px;
    right: -2px;
    color: green;
  }
  .user-details {
    flex: 1;
  }
  .user-name,
  .user-email {
    font-size: 14px;
  }
  .user-name {
    margin-top: 10px;
  }
  .user-email {
    margin-bottom: 10px;
  }
  .inputbox-container {
    padding: 16px;
    width: 300px; /* Adjust the width as needed */
  }
  .pop-close-icon {
    cursor: pointer;
    margin-left: auto;
    margin-right: 15px;
    margin-top: 10px;
  }
  .user-avatar-container {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
  }
  .avatar-circle-large {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-icon-large {
    font-size: 64px;
    color: #555;
  }
  .user-details-container {
    text-align: center;
    margin-bottom: 16px;
  }
  .user-name,
  .user-email {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .button-container button {
    margin-right: auto; /* Push buttons to the left */
  }
    @media (max-width: 1550px) {
     .image {
    width: 140px;
    height: 30px;
    margin-top:55px;
    margin-left:-100px;
  }
    }
    @media (max-width: 1250px) {
     .image {
    width: 100px;
    height: 27px;
    margin-top:55px;
    margin-left:-100px;
  }
     .user-name,
  .user-email {
    font-size: 12px;
  }
    }
  @media (max-width: 1100px) {
     
   .image {
    width: 80px;
    height: 27px;
    margin-top:55px;
    margin-left:-100px;
  
  }
    }
  // @media (max-width: 999px) {
  //    .image {
  //   display:none;
  // }
    }
`}</style>

    </>


  );
};

export default MembersTab;
