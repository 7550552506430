import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { APIURL } from '../Global';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function VerifyOTPResetPasswordComponent() {
  const [mobile, setMobile] = useState('');
  const [otp, setOTP] = useState('');
  const [resetnewPassword, setResetNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  useEffect(() => {
    if (location.state && location.state.identifier) {
      setMobile(location.state.identifier);
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (!mobile || !otp || !resetnewPassword || !confirmPassword) {
      setError('All fields are required.');
      return;
    }

    if (resetnewPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const requestBody = {
      mobile: mobile,
      otp: otp,
      new_password: resetnewPassword,
      confirm_password: confirmPassword
    };

    try {
      const response = await fetch(`${APIURL}/backend/api/otp-reset-password/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status === 'success') {
        setSuccess(data.message);
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setError(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Title>Reset Password</Title>
          
          <InputWrapper>
            <Input
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your mobile number"
              autoComplete="off"
              required
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              type="text"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              placeholder="Enter OTP"
              autoComplete="one-time-code"
              required
            />
          </InputWrapper>

          <InputWrapper>
          <InnerWrapper>
          <Input
              type={showPassword ? "text" : "password"}
              value={resetnewPassword}
              onChange={(e) => setResetNewPassword(e.target.value)}
              placeholder="Enter new password"
              autoComplete="new-password"
              required
            />
          {showPassword ? (
                <VisibilityIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
              ) : (
                <VisibilityOffIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
              )}
          </InnerWrapper>
          </InputWrapper>


          <InputWrapper>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              autoComplete="off"
              required
            />
          </InputWrapper>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}

          <SubmitButton type="submit">Reset Password</SubmitButton>
        </Form>
      </Container>
      <Footer>
        <div style={{ fontWeight: 'bold', marginLeft:'20px' }}>CodeDC</div>
        <div>Copyright@2024 IntPurple Technologies LLP.</div>
        <div style={{ marginRight:'20px' }}>Ver:V1.2.0</div>
      </Footer>
    </>
  );
}

const Footer = styled.footer`
  background-color: #0C2340;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Form = styled.form`
  background-color: #f5f5f5;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  color: #073980;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  margin-top: 5px;
  padding: 8px 30px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #fff;
  background-color: #1976d2;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 20px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  flex-grow: 1;
`;

export default VerifyOTPResetPasswordComponent;