import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupsIcon from '@mui/icons-material/Groups';
import BallotRoundedIcon from '@mui/icons-material/BallotRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MapIcon from '@mui/icons-material/Map';
import LayersIcon from '@mui/icons-material/Layers';
import TollIcon from '@mui/icons-material/Toll';
import Man4Icon from '@mui/icons-material/Man4';
import Woman2Icon from '@mui/icons-material/Woman2';
import TransgenderIcon from '@mui/icons-material/Transgender';
import FlagIcon from '@mui/icons-material/Flag';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BarChartIcon from '@mui/icons-material/BarChart';
import { APIURL } from '../Global';

const ReportsTab = ({ selectedGroupId, selectedGroup, token, tabIndex }) => {
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    if (tabIndex === 0 && selectedGroupId) {
      fetchAnalyticsData(selectedGroupId);
    }
  }, [tabIndex, selectedGroupId]);

  const fetchAnalyticsData = async (groupId) => {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/analytics/?group_id=${groupId}`, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status === 'success') {
        setAnalyticsData(data.data);
      } else {
        console.error('Failed to fetch analytics');
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  const getGenderDetail = (gender) => {
    if (!analyticsData || !analyticsData.analytics || !analyticsData.analytics.gender_breakdown || !analyticsData.analytics.gender_breakdown[gender]) {
      return '0';
    }
    const count = analyticsData.analytics.gender_breakdown[gender].count || 0;
    const percentage = analyticsData.analytics.gender_breakdown[gender].percentage || 0;
    return `${count} (${percentage}%)`;
  };

  const formatProgress = (value) => {
    return `${value ? value.toFixed(2) : 0}%`;
  };

  const tasks = [
    // {
    //   id: 1,
    //   label: 'Group Name',
    //   detail: analyticsData?.group_name ?? 'N/A',
    //   icon: <FlagIcon style={{ color: '#36b73b', fontSize: 31 }} />,
    // },
    // {
    //   id: 2,
    //   label: 'Group Type',
    //   detail: analyticsData?.group_type ?? 'N/A',
    //   icon: <AccountTreeIcon style={{ color: '#17a1fa', fontSize: 30 }} />,
    // },
    // {
    //   id: 3,
    //   label: 'Parent Name',
    //   detail: analyticsData?.parent_name ?? 'N/A',
    //   icon: <FlagIcon style={{ color: '#ff941c', fontSize: 30 }} />,
    // },
    // {
    //   id: 4,
    //   label: 'Parent Type',
    //   detail: analyticsData?.parent_type ?? 'N/A',
    //   icon: <AccountTreeIcon style={{ color: '#ed0000', fontSize: 30 }} />,
    // },
    {
      id: 5,
      label: 'Total Individuals',
      detail: analyticsData?.analytics?.total_individuals ?? 'N/A',
      icon: <GroupsIcon style={{ color: '#36b73b', fontSize: 30 }} />,
    },
    {
      id: 6,
      label: 'Total Voters',
      detail: analyticsData?.analytics?.total_voters ?? 'N/A',
      icon: <BallotRoundedIcon style={{ color: '#17a1fa', fontSize: 30 }} />,
    },
    {
      id: 7,
      label: 'Total Households',
      detail: analyticsData?.analytics?.total_households ?? 'N/A',
      icon: <HouseRoundedIcon style={{ color: '#ff941c', fontSize: 30 }} />,
    },
    {
      id: 8,
      label: 'Total Pensioners',
      detail: analyticsData?.analytics?.total_pensioners ?? 'N/A',
      icon: <PermContactCalendarIcon style={{ color: '#ed0000', fontSize: 30 }} />,
    },
    {
      id: 9,
      label: 'Total National Expatriate',
      detail: analyticsData?.analytics?.total_national_expatriates ?? 'N/A',
      icon: <MapIcon style={{ color: '#36b73b', fontSize: 30 }} />,
    },
    {
      id: 10,
      label: 'Total International Expatriate',
      detail: analyticsData?.analytics?.total_international_expatriates ?? 'N/A',
      icon: <LayersIcon style={{ color: '#17a1fa', fontSize: 30 }} />,
    },
    {
      id: 11,
      label: 'Total Polling Booth',
      detail: analyticsData?.analytics?.total_polling_booths ?? 'N/A',
      icon: <TollIcon style={{ color: '#ff941c', fontSize: 30 }} />,
    },
    {
      id: 12,
      label: 'Male Count',
      detail: getGenderDetail('Male'),
      icon: <Man4Icon style={{ color: '#ed0000', fontSize: 30 }} />,
    },
    {
      id: 13,
      label: 'Female Count',
      detail: getGenderDetail('Female'),
      icon: <Woman2Icon style={{ color: '#36b73b', fontSize: 30 }} />,
    },
    {
      id: 14,
      label: 'Transgender Count',
      detail: getGenderDetail('Transgender'),
      icon: <TransgenderIcon style={{ color: '#17a1fa', fontSize: 30 }} />,
    },
    {
      id: 15,
      label: 'Individual Survey Progress',
      detail: formatProgress(analyticsData?.analytics?.progress?.individual_progress_avg),
      icon: <BarChartIcon style={{ color: '#ff941c', fontSize: 30 }} />,
    },
    {
      id: 16,
      label: 'Household Survey Progress',
      detail: formatProgress(analyticsData?.analytics?.progress?.household_progress_avg),
      icon: <BarChartIcon style={{ color: '#ed0000', fontSize: 30 }} />,
    },
    {
      id: 17,
      label: 'Family Survey Progress',
      detail: formatProgress(analyticsData?.analytics?.progress?.family_progress_avg),
      icon: <BarChartIcon style={{ color: '#36b73b', fontSize: 30 }} />,
    },
    {
      id: 18,
      label: 'Total Progress',
      detail: formatProgress(analyticsData?.analytics?.progress?.total_progress_avg),
      icon: <BarChartIcon style={{ color: '#17a1fa', fontSize: 30 }} />,
    },
  ];

  return (
    <Analytics>
      <TaskTitle>Basic Analysis</TaskTitle>
      <TaskSection>
        {tasks.map(({ id, label, detail, icon }) => (
          <TaskCard key={id}>
            <TaskIconWrapper>{icon}</TaskIconWrapper>
            <TaskLabel>{label}</TaskLabel>
            <TaskDetail>{detail}</TaskDetail>
          </TaskCard>
        ))}
      </TaskSection>
    </Analytics>
  );
};

const Analytics = styled.section`
  background-color: rgba(76, 175, 80, 0.15);
  padding: 15px;
  border: 1px solid rgba(175, 175, 175, 1);
  border-radius: 10px;
  margin-bottom: 20px;

  h3 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #5d5d5d;
    margin-bottom: 20px;
  }
`;

const TaskSection = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Creates three equal columns
  gap: 10px; // Space between cards
  width: 100%;
`;

const TaskTitle = styled.h3`
  text-align: left;
  margin-left: 5px;
`;

const TaskCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px;
`;

const TaskLabel = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #5d5d5d;
`;

const TaskDetail = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
`;

const TaskIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // Center the icon horizontally
  margin-bottom: 10px;
`;



export default ReportsTab;
